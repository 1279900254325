import { departureScheduleApi } from "@/api/departureSchedule.js";
import { carApi } from "@/api/car.js";
import dayjs from "dayjs";

import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";

export default {
  components: { DatePick },
  data: () => ({
    timeStart: dayjs().format("YYYY-MM-DD"),
    isLoading: false,
    listCar: [],
    currentCarId: null,
    listDeparture: [],
  }),
  created() {
    this.getListCar();
  },
  watch: {
    timeStart() {
      this.getListDeparture();
    },
    currentCarId() {
      this.getListDeparture();
    },
  },
  methods: {
    disableDate(date) {
      const currentDate = new Date();
      let featureDate = new Date();
      featureDate.setDate(currentDate.getDate() + 7);

      return date < currentDate || date > featureDate;
    },
    async getListCar() {
      await carApi.getListCar().then((res) => {
        this.listCar = res;
        this.currentCarId = res[0].ID;
      });
    },
    async getListDeparture() {
      this.isLoading = true;
      const loading = this.$vs.loading();
      let params = {
        starTime: this.timeStart,
        carId: this.currentCarId,
      };
      await departureScheduleApi
        .getListDepartureSchedule(params)
        .then((res) => {
          this.listDeparture = res;
        });

      // Tắt loading
      loading.close();
      this.isLoading = false;
    },
    async viewDetailSchedule(Id) {
      return this.$router.push({
        name: "adminScheduleDetail",
        params: { id: Id },
      });
    },
    _dayjs: dayjs,
  },
};
